import React from "react";
import {
  FooterContainer,
  FooterContentContainer,
  FooterText,
  FooterLogo,
  FooterContainerContainer,
  FooterLink,
} from "./styles/Footer.styles";

const Footer = ({element}) => {
  //The element prop takes in the react component and renders it with another FooterContentContainer
  return (
    <FooterContainerContainer>
      <FooterContainer>
        <FooterContentContainer left={true}>
          <FooterLogo>Spingle.ai</FooterLogo>
          <FooterText>© 2024 Cleancut Inc. All rights reserved.</FooterText>
          <FooterLink target="_blank" href="https://drive.google.com/file/d/1vcncTsjaQFCtJ2LiYdmQ89t6GpbUM9Yy/view?usp=sharing">Privacy Policy</FooterLink>
          <FooterLink target="_blank" href="https://drive.google.com/file/d/1tcC5TGeku5vxT8ftP6ESsf4mk3UbW_gA/view?usp=sharing">Terms and Conditions</FooterLink>
        </FooterContentContainer>
        {element}
      </FooterContainer>
    </FooterContainerContainer>
  );
};
export default Footer;
